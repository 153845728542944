<template>
    <div class="login-page">
    <h1 style="text-align: center;">用户注册</h1>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="用户名" prop="name">
        <el-input v-model="ruleForm.name" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="ruleForm.password" placeholder="请输入密码"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
        <el-input type="password" v-model="ruleForm.confirmPassword" placeholder="请输再次输入密码"></el-input>
        </el-form-item>
        <el-form-item style="text-align: left;">
            <el-button type="primary" style="width: 40%;" @click="submitForm('ruleForm')">立即注册</el-button>
        </el-form-item>
    </el-form>
    </div>
</template>
  <script>
    export default {
      data() {
        return {
          ruleForm: {
            name: '',
            password: '',
          },
          rules: {
            name: [
              { required: true, message: '请输入用户名', trigger: 'blur' },
            ],
            password: [
              { required: true, message: '请输入密码', trigger: 'blur' }
            ],
            confirmPassword: [
              { required: true, message: '请输入密码', trigger: 'blur' }
            ],
          }
        };
      },
      methods: {
        submitForm(formName) {
          this.$refs[formName].validate((valid) => {
           console.log(valid);
          });
        },
      }
    }
  </script>
<style>
.login-page{
    width: 500px;
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -50%);
}
</style>